const { v4: uuidv4
} = require('uuid');

// List of available payload options for executing pre-configured code
export const options = [
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "device.network.lan": true
      }
    },
    description: "Toggle Network to LAN",
    destination: "cmd/action"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "device.network.wlan": true
      }
    },
    description: "Toggle Network to WLAN",
    destination: "cmd/action"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "device.reset.mqtt": true
      }
    },
    description: "Discard Changes and Restart to MQTT",
    destination: "cmd/action"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "device.reset.ble": true
      }
    },
    description: "Discard Changes and Restart to BLE",
    destination: "cmd/action"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "device.save.mqtt": true
      }
    },
    description: "Save Changes and Restart to MQTT",
    destination: "cmd/action"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "device.save.ble": true
      }
    },
    description: "Save Changes and Restart to BLE",
    destination: "cmd/action"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "di.counter.reset": true
      }
    },
    description: "Reset Digital Input Counters",
    destination: "cmd/action"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "io.publish": true
      }
    },
    description: "Publish I/O Data",
    destination: "cmd/action"
  },
  {
    payload: {
      "id": uuidv4(),
      "ud.set": {
        "user": "object"
      }
    },
    description: "Set User Data",
    destination: "cmd/action"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "device.ota": {
          "bin": "{direct-download-uri-to-binary}"
        }
      }
    },
    description: "OTA Update",
    destination: "cmd/action"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "file.save": {
          "name": "client.crt",
          "b64": "{base64-encoded-file-contents}"
        }
      }
    },
    description: "Save File",
    destination: "cmd/action"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "file.delete": {
          "name": "client.crt"
        }
      }
    },
    description: "Delete File",
    destination: "cmd/action"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "file.read": {
          "name": "client.crt"
        }
      }
    },
    description: "Read File",
    destination: "cmd/action"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "file.list": {
          "path": "/"
        }
      }
    },
    description: "List Files",
    destination: "cmd/action"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "lan.static_ip": 1,
        "lan.ip_config.ip": "10.1.10.201",
        "lan.ip_config.gw": "10.1.10.1",
        "lan.ip_config.mask": "255.255.255.0"
      }
    },
    description: "Set LAN Static IP",
    destination: "cmd/cfg"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "lan.static_ip": 0
      }
    },
    description: "Set LAN DHCP",
    destination: "cmd/cfg"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "wlan.static_ip": 1,
        "wlan.ip_config.ip": "192.168.111.111",
        "wlan.ip_config.gw": "192.168.111.1",
        "wlan.ip_config.mask": "255.255.255.0",
        "wlan.ip_config.dns": "8.8.8.8",
        "wlan.ssid": "SHARC",
        "wlan.pass": "frenzyengineering"
      }
    },
    description: "Set WLAN Static IP",
    destination: "cmd/cfg"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "wlan.static_ip": 0,
        "wlan.ssid": "SHARC",
        "wlan.pass": "frenzyengineering"
      }
    },
    description: "Set WLAN DHCP",
    destination: "cmd/cfg"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "mqtt.interval_ms": 100,
        "mqtt.broker.address": "137.184.193.22",
        "mqtt.broker.port": 1883,
        "mqtt.broker.user": "",
        "mqtt.broker.pass": "",
        "mqtt.broker.ka_s": 60,
        "mqtt.broker.ping_s": 5,
        "mqtt.broker.ssl": false
      }
    },
    description: "Change MQTT Broker",
    destination: "cmd/cfg"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "sensor.s0.mode": "switch",
        "sensor.s0.edge": "any",
        "sensor.s0.persist": false,
        "sensor.s0.period": 1000,
        "sensor.s0.transform": "(v, '/', False)",
        "sensor.s1.mode": "switch",
        "sensor.s1.edge": "any",
        "sensor.s1.persist": false,
        "sensor.s1.period": 1000,
        "sensor.s1.transform": "(v, '/', False)",
        "sensor.s2.deadband": 100,
        "sensor.s2.transform": "(float('{:.1f}'.format((v * 0.000384615 - 0) / (10 - 0) * (10 - 0) + 0)), 'v', False)",
        "sensor.s3.deadband": 100,
        "sensor.s3.transform": "(float('{:.1f}'.format((v * 0.00075 - 4) / (20 - 4) * (20 - 4) + 4)), 'mA', False)"
      }
    },
    description: "Set Sensor Defaults",
    destination: "cmd/cfg"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "sensor.s0.mode": "counter",
        "sensor.s0.edge": "rising",
        "sensor.s0.persist": false,
        "sensor.s0.period": 1000,
        "sensor.s0.transform": "(v, 'count', False)",
      }
    },
    description: "Set Sensor Counter",
    destination: "cmd/cfg"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "sensor.s0.mode": "accumulator",
        "sensor.s0.edge": "falling",
        "sensor.s0.persist": false,
        "sensor.s0.period": 1000,
        "sensor.s0.transform": "(v, 'count', False)",
      }
    },
    description: "Set Sensor Accumulator",
    destination: "cmd/cfg"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "rtu.setup.baud_rate": 115200,
        "rtu.setup.read_timeout_chars": 120,
        "rtu.enabled": true,
        "rtu.registers": {
          "distance": {
            "fc": 3,
            "qty": 1,
            "enabled": true,
            "id": 81,
            "addr": 52,
            "transform": "(v[0], 'mm', False)"
          }
        },
        "rtu.interval_ms": 100
      }
    },
    description: "RTU WT53R",
    destination: "cmd/cfg"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "rtu.setup.baud_rate": 9600,
        "rtu.setup.read_timeout_chars": 120,
        "rtu.enabled": true,
        "rtu.registers": {
          "Yfdnf": {
            "fc": 3,
            "addr": 72,
            "qty": 1,
            "transform": "(v[0], 'um', False)",
            "enabled": true,
            "id": 80
          },
          "Zfdnf": {
            "fc": 3,
            "addr": 73,
            "qty": 1,
            "transform": "(v[0], 'um', False)",
            "enabled": true,
            "id": 80
          },
          "Xhz": {
            "fc": 3,
            "addr": 68,
            "qty": 1,
            "transform": "(v[0], 'Hz', False)",
            "enabled": true,
            "id": 80
          },
          "Yad": {
            "fc": 3,
            "addr": 62,
            "qty": 1,
            "transform": "((v[0]/32768)*180, 'degrees', False)",
            "enabled": true,
            "id": 80
          },
          "Zad": {
            "fc": 3,
            "addr": 63,
            "qty": 1,
            "transform": "((v[0]/32768)*180, 'degrees', False)",
            "enabled": true,
            "id": 80
          },
          "Yd": {
            "fc": 3,
            "addr": 66,
            "qty": 1,
            "transform": "(v[0], 'um', False)",
            "enabled": true,
            "id": 80
          },
          "Yhz": {
            "fc": 3,
            "addr": 69,
            "qty": 1,
            "transform": "(v[0], 'Hz', False)",
            "enabled": true,
            "id": 80
          },
          "Yv": {
            "fc": 3,
            "addr": 59,
            "qty": 1,
            "transform": "(v[0], 'mm/s', False)",
            "enabled": true,
            "id": 80
          },
          "Xad": {
            "fc": 3,
            "addr": 61,
            "qty": 1,
            "transform": "((v[0]/32768)*180, 'degrees', False)",
            "enabled": true,
            "id": 80
          },
          "Xd": {
            "fc": 3,
            "addr": 65,
            "qty": 1,
            "transform": "(v[0], 'um', False)",
            "enabled": true,
            "id": 80
          },
          "Zhz": {
            "fc": 3,
            "addr": 70,
            "qty": 1,
            "transform": "(v[0], 'Hz', False)",
            "enabled": true,
            "id": 80
          },
          "Xv": {
            "fc": 3,
            "addr": 58,
            "qty": 2,
            "transform": "(v[0], 'mm/s', False)",
            "enabled": true,
            "id": 80
          },
          "temperature": {
            "fc": 3,
            "addr": 64,
            "qty": 1,
            "transform": "(v[0]/100, 'Cel', False)",
            "enabled": true,
            "id": 80
          },
          "Zv": {
            "fc": 3,
            "addr": 60,
            "qty": 1,
            "transform": "(v[0], 'mm/s', False)",
            "enabled": true,
            "id": 80
          },
          "Zd": {
            "fc": 3,
            "addr": 67,
            "qty": 1,
            "transform": "(v[0], 'um', False)",
            "enabled": true,
            "id": 80
          },
          "Xfdnf": {
            "fc": 3,
            "addr": 71,
            "qty": 1,
            "transform": "(v[0], 'um', False)",
            "enabled": true,
            "id": 80
          }
        },
        "rtu.interval_ms": 100
      }
    },
    description: "RTU WTVB01",
    destination: "cmd/cfg"
  },
  {
    payload: {
      "id": uuidv4(),
      "v": {
        "rtu.setup.baud_rate": 9600,
        "rtu.setup.read_timeout_chars": 320,
        "rtu.enabled": true,
        "rtu.registers": {
          "temperature": {
            "fc": 4,
            "qty": 1,
            "enabled": true,
            "id": 1,
            "addr": 1,
            "transform": "(v[0]/100, 'Cel', False)"
          },
          "humidity": {
            "fc": 4,
            "qty": 1,
            "enabled": true,
            "id": 1,
            "addr": 2,
            "transform": "(v[0]/100, '%RH', False)"
          }
        },
        "rtu.interval_ms": 100
      }
    },
    description: "RTU WTVB01",
    destination: "cmd/cfg"
  },
]